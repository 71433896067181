import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// utils
import { localStorageGetItem } from '@utils/storage-available';
//
import { defaultLang } from './config-lang';
//
import translationEn from './langs/en.json';
import translationFr from './langs/fr.json';
import translationAr from './langs/ar.json';
import translationDe from './langs/de.json';
import translationEs from './langs/es.json';
import translationHi from './langs/hi.json';
import translationIt from './langs/it.json';
import translationRu from './langs/ru.json';
import translationSr from './langs/sr.json';
import translationTr from './langs/tr.json';
import translationZh from './langs/zh.json';

// ----------------------------------------------------------------------

const lng = localStorageGetItem('i18nextLng', defaultLang.value);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: translationEn },
      fr: { translations: translationFr },
      ar: { translations: translationAr },
      zh: { translations: translationZh },
      ru: { translations: translationRu },
      de: { translations: translationDe },
      es: { translations: translationEs },
      sr: { translations: translationSr },
      tr: { translations: translationTr },
      it: { translations: translationIt },
      hi: { translations: translationHi },
    },
    lng,
    fallbackLng: lng,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
