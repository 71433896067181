import React, {
  createContext,
  useState,
  useMemo,
  SetStateAction,
  Dispatch,
  useCallback,
} from 'react';

import { SortColumn } from '@redux/interfaces/commonEnums';
import { UserStatus } from '@redux/interfaces/user/enum';
import { Patient } from '@redux/interfaces/patients';

interface PatientsContextProps {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  dense: boolean;
  setDense: Dispatch<SetStateAction<boolean>>;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  sortColumnValue: string;
  setSortColumnValue: Dispatch<SetStateAction<string>>;
  sortColumnDirection: number;
  setSortColumnDirection: Dispatch<SetStateAction<number>>;
  userStatus: UserStatus | 'all';
  setUserStatus: Dispatch<SetStateAction<UserStatus | 'all'>>;
  languageId: number | null;
  setLanguageId: Dispatch<SetStateAction<number | null>>;
  countryId: number | null;
  setCountryId: Dispatch<SetStateAction<number | null>>;
  patientInfo: Patient | null;
  setPatientInfo: Dispatch<SetStateAction<Patient | null>>;
  resetPatientsContext: Function;
}

export const PatientsContext = createContext<PatientsContextProps>({
  currentPage: 1,
  setCurrentPage: () => {},
  rowsPerPage: 5,
  setRowsPerPage: () => {},
  dense: false,
  setDense: () => {},
  searchValue: '',
  setSearchValue: () => {},
  sortColumnValue: SortColumn.FIRST_NAME,
  setSortColumnValue: () => {},
  sortColumnDirection: 1,
  setSortColumnDirection: () => {},
  userStatus: 'all',
  setUserStatus: () => {},
  languageId: null,
  setLanguageId: () => {},
  countryId: null,
  setCountryId: () => {},
  patientInfo: null,
  setPatientInfo: () => {},
  resetPatientsContext: () => {},
});

interface PatientsProviderProps {
  children: React.ReactNode;
}

export const PatientsProvider: React.FC<PatientsProviderProps> = ({
  children,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [dense, setDense] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortColumnValue, setSortColumnValue] = useState<string>(
    SortColumn.FIRST_NAME
  );
  const [sortColumnDirection, setSortColumnDirection] = useState<number>(1);
  const [userStatus, setUserStatus] = useState<UserStatus | 'all'>('all');
  const [languageId, setLanguageId] = useState<number | null>(null);
  const [countryId, setCountryId] = useState<number | null>(null);
  const [patientInfo, setPatientInfo] = useState<Patient | null>(null);

  const resetPatientsContext = useCallback(() => {
    setCurrentPage(1);
    setRowsPerPage(5);
    setDense(false);
    setSearchValue('');
    setSortColumnValue(SortColumn.FIRST_NAME);
    setSortColumnDirection(1);
    setUserStatus('all');
    setLanguageId(null);
    setCountryId(null);
    setPatientInfo(null);
  }, [
    setCurrentPage,
    setRowsPerPage,
    setDense,
    setSearchValue,
    setSortColumnValue,
    setSortColumnDirection,
    setUserStatus,
    setLanguageId,
    setCountryId,
    setPatientInfo,
  ]);

  const CONTEXT_VALUE = useMemo(
    () => ({
      currentPage,
      setCurrentPage,
      rowsPerPage,
      setRowsPerPage,
      dense,
      setDense,
      searchValue,
      setSearchValue,
      sortColumnValue,
      setSortColumnValue,
      sortColumnDirection,
      setSortColumnDirection,
      userStatus,
      setUserStatus,
      languageId,
      setLanguageId,
      countryId,
      setCountryId,
      patientInfo,
      setPatientInfo,
      resetPatientsContext,
    }),
    [
      currentPage,
      setCurrentPage,
      rowsPerPage,
      setRowsPerPage,
      dense,
      setDense,
      searchValue,
      setSearchValue,
      sortColumnValue,
      setSortColumnValue,
      sortColumnDirection,
      setSortColumnDirection,
      userStatus,
      setUserStatus,
      languageId,
      setLanguageId,
      countryId,
      setCountryId,
      patientInfo,
      setPatientInfo,
      resetPatientsContext,
    ]
  );

  return (
    <PatientsContext.Provider value={CONTEXT_VALUE}>
      {children}
    </PatientsContext.Provider>
  );
};
