import React, {
  createContext,
  useState,
  useMemo,
  SetStateAction,
  Dispatch,
  useCallback,
} from 'react';

import { SortColumn } from '@redux/interfaces/commonEnums';
import { UserStatus } from '@redux/interfaces/user/enum';
import {
  Doctor,
  DoctorDocument,
  DoctorEducation,
  DoctorWorkExperience,
} from '@redux/interfaces/doctors';

interface DoctorsContextProps {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  dense: boolean;
  setDense: Dispatch<SetStateAction<boolean>>;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  sortColumnValue: string;
  setSortColumnValue: Dispatch<SetStateAction<string>>;
  sortColumnDirection: number;
  setSortColumnDirection: Dispatch<SetStateAction<number>>;
  userStatus: UserStatus | 'all';
  setUserStatus: Dispatch<SetStateAction<UserStatus | 'all'>>;
  departmentId: number | null;
  setDepartmentId: Dispatch<SetStateAction<number | null>>;
  languageId: number | null;
  setLanguageId: Dispatch<SetStateAction<number | null>>;
  countryId: number | null;
  setCountryId: Dispatch<SetStateAction<number | null>>;
  priceFilter: string | null;
  setPriceFilter: Dispatch<SetStateAction<string | null>>;
  doctorInfo: Doctor | null;
  setDoctorInfo: Dispatch<SetStateAction<Doctor | null>>;
  doctorDocuments: DoctorDocument[] | null;
  setDoctorDocuments: Dispatch<SetStateAction<DoctorDocument[] | null>>;
  doctorEducations: DoctorEducation[] | null;
  setDoctorEducations: Dispatch<SetStateAction<DoctorEducation[] | null>>;
  doctorWorkExperiences: DoctorWorkExperience[] | null;
  setDoctorWorkExperiences: Dispatch<
    SetStateAction<DoctorWorkExperience[] | null>
  >;
  resetDoctorsContext: Function;
}

export const DoctorsContext = createContext<DoctorsContextProps>({
  currentPage: 1,
  setCurrentPage: () => {},
  rowsPerPage: 5,
  setRowsPerPage: () => {},
  dense: false,
  setDense: () => {},
  searchValue: '',
  setSearchValue: () => {},
  sortColumnValue: SortColumn.FIRST_NAME,
  setSortColumnValue: () => {},
  sortColumnDirection: 1,
  setSortColumnDirection: () => {},
  userStatus: 'all',
  setUserStatus: () => {},
  departmentId: null,
  setDepartmentId: () => {},
  languageId: null,
  setLanguageId: () => {},
  countryId: null,
  setCountryId: () => {},
  priceFilter: null,
  setPriceFilter: () => {},
  doctorInfo: null,
  setDoctorInfo: () => {},
  doctorDocuments: null,
  setDoctorDocuments: () => {},
  doctorEducations: null,
  setDoctorEducations: () => {},
  doctorWorkExperiences: null,
  setDoctorWorkExperiences: () => {},
  resetDoctorsContext: () => {},
});

interface DoctorsProviderProps {
  children: React.ReactNode;
}

export const DoctorsProvider: React.FC<DoctorsProviderProps> = ({
  children,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [dense, setDense] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortColumnValue, setSortColumnValue] = useState<string>(
    SortColumn.FIRST_NAME
  );
  const [sortColumnDirection, setSortColumnDirection] = useState<number>(1);
  const [userStatus, setUserStatus] = useState<UserStatus | 'all'>('all');
  const [departmentId, setDepartmentId] = useState<number | null>(null);
  const [languageId, setLanguageId] = useState<number | null>(null);
  const [countryId, setCountryId] = useState<number | null>(null);
  const [priceFilter, setPriceFilter] = useState<string | null>(null);
  const [doctorInfo, setDoctorInfo] = useState<Doctor | null>(null);
  const [doctorDocuments, setDoctorDocuments] = useState<
    DoctorDocument[] | null
  >(null);
  const [doctorEducations, setDoctorEducations] = useState<
    DoctorEducation[] | null
  >(null);
  const [doctorWorkExperiences, setDoctorWorkExperiences] = useState<
    DoctorWorkExperience[] | null
  >(null);

  const resetDoctorsContext = useCallback(() => {
    setCurrentPage(1);
    setRowsPerPage(5);
    setDense(false);
    setSearchValue('');
    setSortColumnValue(SortColumn.FIRST_NAME);
    setSortColumnDirection(1);
    setUserStatus('all');
    setDepartmentId(null);
    setLanguageId(null);
    setCountryId(null);
    setPriceFilter(null);
    setDoctorInfo(null);
    setDoctorDocuments(null);
    setDoctorEducations(null);
    setDoctorWorkExperiences(null);
  }, [
    setCurrentPage,
    setRowsPerPage,
    setDense,
    setSearchValue,
    setSortColumnValue,
    setSortColumnDirection,
    setUserStatus,
    setDepartmentId,
    setLanguageId,
    setCountryId,
    setPriceFilter,
    setDoctorInfo,
    setDoctorDocuments,
    setDoctorEducations,
    setDoctorWorkExperiences,
  ]);

  const CONTEXT_VALUE = useMemo(
    () => ({
      currentPage,
      setCurrentPage,
      rowsPerPage,
      setRowsPerPage,
      dense,
      setDense,
      searchValue,
      setSearchValue,
      sortColumnValue,
      setSortColumnValue,
      sortColumnDirection,
      setSortColumnDirection,
      userStatus,
      setUserStatus,
      departmentId,
      setDepartmentId,
      languageId,
      setLanguageId,
      countryId,
      setCountryId,
      priceFilter,
      setPriceFilter,
      doctorInfo,
      setDoctorInfo,
      doctorDocuments,
      setDoctorDocuments,
      doctorEducations,
      setDoctorEducations,
      doctorWorkExperiences,
      setDoctorWorkExperiences,
      resetDoctorsContext,
    }),
    [
      currentPage,
      setCurrentPage,
      rowsPerPage,
      setRowsPerPage,
      dense,
      setDense,
      searchValue,
      setSearchValue,
      sortColumnValue,
      setSortColumnValue,
      sortColumnDirection,
      setSortColumnDirection,
      userStatus,
      setUserStatus,
      departmentId,
      setDepartmentId,
      languageId,
      setLanguageId,
      countryId,
      setCountryId,
      priceFilter,
      setPriceFilter,
      doctorInfo,
      setDoctorInfo,
      doctorDocuments,
      setDoctorDocuments,
      doctorEducations,
      setDoctorEducations,
      doctorWorkExperiences,
      setDoctorWorkExperiences,
      resetDoctorsContext,
    ]
  );

  return (
    <DoctorsContext.Provider value={CONTEXT_VALUE}>
      {children}
    </DoctorsContext.Provider>
  );
};
