import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetLanguagesResponse, Language } from '@redux/interfaces/languages';
import { RootState } from '@redux/store';

export interface ILanguagesState {
  allLanguages: Language[] | [];
}

const initialState: ILanguagesState = {
  allLanguages: [],
};

export const languages = createSlice({
  initialState,
  name: 'languages',
  reducers: {
    setAllLanguages: (state, action: PayloadAction<GetLanguagesResponse[]>) => {
      state.allLanguages = action.payload;
    },
  },
});

export default languages.reducer;

export const { setAllLanguages } = languages.actions;

export const selectAllLanguages = (state: RootState): Language[] | [] =>
  state.languages.allLanguages;
