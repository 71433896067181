import { Provider as ReduxProvider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
// i18n
import '@locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// routes
import Router from '@src/routes/sections';
// theme
import ThemeProvider from '@src/theme';
// hooks
import { useScrollToTop } from '@hooks/use-scroll-to-top';
// components
import ProgressBar from '@components/progress-bar';
import MotionLazy from '@components/animate/motion-lazy';
import { SettingsProvider, SettingsDrawer } from '@components/settings';
// auth
import { AuthProvider } from '@src/auth/context/jwt';
// redux
import store from './redux/store';
import { DoctorsProvider } from './layouts/doctors/DoctorsProvider';
import { PatientsProvider } from './layouts/patients/PatientsProvider';
import { DiscountsProvider } from './layouts/discounts/DiscountsProvider';
import { DepartmentsProvider } from './layouts/departments/DepartmentsProvider';
// css
import 'react-toastify/dist/ReactToastify.css';

// ----------------------------------------------------------------------

export default function App() {
  useScrollToTop();

  return (
    <ReduxProvider store={store}>
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'default', // 'default' | 'bold'
              themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: false,
            }}
          >
            <ThemeProvider>
              <MotionLazy>
                <ToastContainer position='bottom-left' />
                <DoctorsProvider>
                  <DepartmentsProvider>
                    <PatientsProvider>
                      <DiscountsProvider>
                        <SettingsDrawer />
                        <ProgressBar />
                        <Router />
                      </DiscountsProvider>
                    </PatientsProvider>
                  </DepartmentsProvider>
                </DoctorsProvider>
              </MotionLazy>
            </ThemeProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </AuthProvider>
    </ReduxProvider>
  );
}
