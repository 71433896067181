export enum UserType {
  PATIENT = 'PATIENT',
  DOCTOR = 'DOCTOR',
  ADMIN = 'ADMIN'
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
  DEACTIVATED = 'DEACTIVATED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum WithdrawalStatus {
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
}

export enum DiscountStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum DoctorDocumentStatus {
  APPROVED = 'APPROVED',
  NOT_APPROVED = 'NOT_APPROVED',
  REJECTED= 'REJECTED',
}

export enum DoctorDocumentFileType {
  CV = 'CV',
  ID = 'ID',
  DEGREE = 'DEGREE',
  PROFILE_PICTURE = 'PROFILE_PICTURE',
}

export enum PatientDocumentFileType {
  PROFILE_PICTURE = 'PROFILE_PICTURE',
}

export enum UserDocumentFileType {
  PROFILE_PICTURE = 'PROFILE_PICTURE',
}