import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Country } from '@redux/interfaces/countries';
import { RootState } from '@redux/store';

export interface ICountriesState {
  countries: Country[] | [];
}

const initialState: ICountriesState = {
  countries: [],
};

export const countries = createSlice({
  initialState,
  name: 'countries',
  reducers: {
    setAllCountries: (state, action: PayloadAction<Country[]>) => {
      state.countries = action.payload;
    },
  },
});

export default countries.reducer;

export const { setAllCountries } = countries.actions;

export const selectAllCountries = (state: RootState): Country[] =>
  state.countries.countries;
