// ----------------------------------------------------------------------

export enum HttpMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export enum LocalStorageNames {
  TOKEN = 'accessToken',
}
