import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from '@src/auth/guard';
// layouts
import DashboardLayout from '@layouts/dashboard';
// components
import { LoadingScreen } from '@components/loading-screen';

// ----------------------------------------------------------------------

const DepartmentNewPage = lazy(() => import('@pages/department/new'));
const DepartmentListPage = lazy(() => import('@pages/department/list'));
const DepartmentDetailsPage = lazy(() => import('@pages/department/details'));

// ----------------------------------------------------------------------

export const departmentsRoutes = [
  {
    path: 'departments',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <DepartmentListPage />, index: true },
      { path: 'new', element: <DepartmentNewPage /> },
      { path: ':id', element: <DepartmentDetailsPage /> },
    ],
  },
];
