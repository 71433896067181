import { toast } from 'react-toastify';

import { HttpMethods } from '@constants/enums';
import { baseCreateApi } from '@redux/api/baseCreateApi';
// redux
import { setDoctorNewUserStatus } from '@redux/slices/doctors';
import {
  setAllPatients,
  setPatientNewUserStatus,
} from '@redux/slices/patients';
import {
  setWithdrawalNewStatus,
  setWithdrawals,
} from '@redux/slices/withdrawals';
// utils
import { generateUrlWithQueryParams } from '@utils/url';
// interfaces
import {
  GetWithdrawalsResponse,
  ApproveDoctorRequest,
  ApproveDoctorResponse,
  ApproveWithdrawalRequest,
  ApproveWithdrawalResponse,
  RejectDoctorRequest,
  RejectDoctorResponse,
  RejectWithdrawalRequest,
  RejectWithdrawalResponse,
  GetDoctorDocumentsRequest,
  GetDoctorDocumentsResponse,
  ApproveRejectDoctorDocumentResponse,
  ApproveRejectDoctorDocumentRequest,
  DeleteDoctorDocumentResponse,
  DeleteDoctorDocumentRequest,
  UploadDoctorDocumentResponse,
  UploadDoctorDocumentRequest,
  CreateDoctorRequest,
  CreateUpdateDoctorResponse,
  UpdateDoctorRequest,
  UpdateDoctorRatesResponse,
  UpdateDoctorRatesRequest,
  GetDoctorEducationsResponse,
  GetDoctorEducationsRequest,
  GetDoctorWorkExperiencesResponse,
  GetDoctorWorkExperiencesRequest,
  DeleteDoctorEducationResponse,
  DeleteDoctorEducationRequest,
  DeleteDoctorWorkExperienceRequest,
  DeleteDoctorWorkExperienceResponse,
  CreateDoctorEducationResponse,
  CreateDoctorEducationRequest,
  CreateDoctorWorkExperienceRequest,
  CreateDoctorWorkExperienceResponse,
  GetAdminUserByIdRequest,
  GetAdminUserByIdResponse,
  GetAdminUsersResponse,
  CreateAdminUserRequest,
  CreateAdminUserResponse,
  UpdateAdminUserResponse,
  UpdateAdminUserRequest,
  UpdateAdminUserProfilePictureRequest,
  UpdateAdminUserProfilePictureResponse,
  ActivatePatientResponse,
  ActivatePatientRequest,
  DeactivatePatientResponse,
  DeactivatePatientRequest,
  DeletePatientRequest,
  DeletePatientResponse,
  GetPaginatedPatientsApiRequest,
  GetPaginatedpatientsApiResponse,
  GetPatientByIdRequest,
  GetPatientByIdResponse,
  CreateUpdatePatientResponse,
  CreateUpdatePatientRequest,
  UpdatePatientProfilePictureRequest,
  UpdatePatientProfilePictureResponse,
} from '../interfaces/admin';
import { UserStatus, WithdrawalStatus } from '../interfaces/user/enum';

export const adminApi = baseCreateApi.injectEndpoints({
  endpoints: builder => ({
    approveDoctor: builder.query<ApproveDoctorResponse, ApproveDoctorRequest>({
      query({ doctorId }) {
        return {
          url: `/admin/doctors/${doctorId}/approve`,
          method: HttpMethods.POST,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled.then(() => {
            dispatch(
              setDoctorNewUserStatus({
                id: args.doctorId,
                status: UserStatus.ACTIVE,
              })
            );
            toast.success('Doctor approved successfully');
          });
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    rejectDoctor: builder.query<RejectDoctorResponse, RejectDoctorRequest>({
      query({ doctorId }) {
        return {
          url: `/admin/doctors/${doctorId}/reject`,
          method: HttpMethods.POST,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled.then(() => {
            dispatch(
              setDoctorNewUserStatus({
                id: args.doctorId,
                status: UserStatus.REJECTED,
              })
            );
            toast.success('Doctor rejected successfully');
          });
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    getAllDoctorDocumentsByDoctorId: builder.query<
      GetDoctorDocumentsResponse,
      GetDoctorDocumentsRequest
    >({
      query({ doctorId }) {
        return {
          url: `/admin/doctors/${doctorId}/documents`,
          method: HttpMethods.GET,
          isWithAuth: true,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error);
        }
      },
    }),
    approveDoctorDocument: builder.query<
      ApproveRejectDoctorDocumentResponse,
      ApproveRejectDoctorDocumentRequest
    >({
      query({ doctorId, documentId }) {
        return {
          url: `/admin/doctors/${doctorId}/documents/${documentId}/approve`,
          method: HttpMethods.POST,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    rejectDoctorDocument: builder.query<
      ApproveRejectDoctorDocumentResponse,
      ApproveRejectDoctorDocumentRequest
    >({
      query({ doctorId, documentId }) {
        return {
          url: `/admin/doctors/${doctorId}/documents/${documentId}/reject`,
          method: HttpMethods.POST,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    deleteDoctorDocument: builder.query<
      DeleteDoctorDocumentResponse,
      DeleteDoctorDocumentRequest
    >({
      query({ doctorId, documentId }) {
        return {
          url: `/admin/doctors/${doctorId}/documents/${documentId}`,
          method: HttpMethods.DELETE,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    getAllWithdrawals: builder.query<GetWithdrawalsResponse[], void>({
      query() {
        return {
          url: '/admin/withdrawals/',
          method: HttpMethods.GET,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled.then(({ data }) => {
            if (data) dispatch(setWithdrawals(data));
          });
        } catch (error) {
          toast.error(error);
        }
      },
    }),
    approveWithdrawal: builder.query<
      ApproveWithdrawalResponse,
      ApproveWithdrawalRequest
    >({
      query({ doctorId, withdrawalId }) {
        return {
          url: `/admin/doctors/${doctorId}/withdrawals/${withdrawalId}/pay`,
          method: HttpMethods.PUT,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled.then(() => {
            dispatch(
              setWithdrawalNewStatus({
                id: args.withdrawalId,
                status: WithdrawalStatus.APPROVED,
              })
            );
            toast.success('Withdrawal paid successfully');
          });
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    rejectWithdrawal: builder.query<
      RejectWithdrawalResponse,
      RejectWithdrawalRequest
    >({
      query({ doctorId, withdrawalId }) {
        return {
          url: `/admin/doctors/${doctorId}/withdrawals/${withdrawalId}/reject`,
          method: HttpMethods.PUT,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled.then(() => {
            dispatch(
              setWithdrawalNewStatus({
                id: args.withdrawalId,
                status: WithdrawalStatus.REJECTED,
              })
            );
            toast.success('Withdrawal rejected successfully');
          });
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    uploadDoctorDocument: builder.mutation<
      UploadDoctorDocumentResponse,
      UploadDoctorDocumentRequest
    >({
      query({ doctorId, fileType, formData }) {
        return {
          url: `/admin/doctors/upload/${doctorId}?fileType=${fileType}`,
          method: HttpMethods.POST,
          body: formData,
          useFormData: true,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    createDoctor: builder.mutation<
      CreateUpdateDoctorResponse,
      CreateDoctorRequest
    >({
      query(data) {
        return {
          url: `/admin/doctors/create`,
          method: HttpMethods.POST,
          body: data,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    updateDoctor: builder.mutation<
      CreateUpdateDoctorResponse,
      UpdateDoctorRequest
    >({
      query({ id, data }) {
        return {
          url: `/admin/doctors/${id}/edit`,
          method: HttpMethods.PUT,
          body: data,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    updateDoctorRates: builder.mutation<
      UpdateDoctorRatesResponse,
      UpdateDoctorRatesRequest
    >({
      query({ id, data }) {
        return {
          url: `/admin/doctors/${id}/rates`,
          method: HttpMethods.PUT,
          body: data,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    getAllDoctorEducationsByDoctorId: builder.query<
      GetDoctorEducationsResponse,
      GetDoctorEducationsRequest
    >({
      query({ doctorId }) {
        return {
          url: `/admin/doctors/${doctorId}/education`,
          method: HttpMethods.GET,
          isWithAuth: true,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error);
        }
      },
    }),
    getAllDoctorWorkExperiencesByDoctorId: builder.query<
      GetDoctorWorkExperiencesResponse,
      GetDoctorWorkExperiencesRequest
    >({
      query({ doctorId }) {
        return {
          url: `/admin/doctors/${doctorId}/work_experience`,
          method: HttpMethods.GET,
          isWithAuth: true,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error);
        }
      },
    }),
    deleteDoctorEducation: builder.query<
      DeleteDoctorEducationResponse,
      DeleteDoctorEducationRequest
    >({
      query({ doctorId, educationId }) {
        return {
          url: `/admin/doctors/${doctorId}/education/${educationId}`,
          method: HttpMethods.DELETE,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    deleteDoctorWorkExperience: builder.query<
      DeleteDoctorWorkExperienceResponse,
      DeleteDoctorWorkExperienceRequest
    >({
      query({ doctorId, workExperienceId }) {
        return {
          url: `/admin/doctors/${doctorId}/work_experience/${workExperienceId}`,
          method: HttpMethods.DELETE,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    createDoctorEducation: builder.mutation<
      CreateDoctorEducationResponse,
      CreateDoctorEducationRequest
    >({
      query({ id, data }) {
        return {
          url: `/admin/doctors/${id}/education`,
          method: HttpMethods.POST,
          body: data,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    createDoctorWorkExperience: builder.mutation<
      CreateDoctorWorkExperienceResponse,
      CreateDoctorWorkExperienceRequest
    >({
      query({ id, data }) {
        return {
          url: `/admin/doctors/${id}/work_experience`,
          method: HttpMethods.POST,
          body: data,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    getAdminUsers: builder.query<GetAdminUsersResponse, null>({
      query() {
        return {
          url: `/admin/users`,
          method: HttpMethods.GET,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    getAdminUserById: builder.query<
      GetAdminUserByIdResponse,
      GetAdminUserByIdRequest
    >({
      query({ adminId }) {
        return {
          url: `/admin/users/${adminId}`,
          method: HttpMethods.GET,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    createAdminUser: builder.mutation<
      CreateAdminUserResponse,
      CreateAdminUserRequest
    >({
      query(data) {
        return {
          url: `/admin/users`,
          method: HttpMethods.POST,
          body: data,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    updateAdminUser: builder.mutation<
      UpdateAdminUserResponse,
      UpdateAdminUserRequest
    >({
      query(data) {
        return {
          url: `/admin/users`,
          method: HttpMethods.PUT,
          body: data,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    updateAdminUserProfilePicture: builder.mutation<
      UpdateAdminUserProfilePictureResponse,
      UpdateAdminUserProfilePictureRequest
    >({
      query({ adminId, formData }) {
        return {
          url: `/admin/users/${adminId}/uploadpicture`,
          method: HttpMethods.POST,
          body: formData,
          useFormData: true,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    deletePatient: builder.query<DeletePatientResponse, DeletePatientRequest>({
      query({ patientId }) {
        return {
          url: `/admin/patients/${patientId}`,
          method: HttpMethods.DELETE,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    getPaginatedPatients: builder.query<
      GetPaginatedpatientsApiResponse,
      GetPaginatedPatientsApiRequest
    >({
      query({
        limit,
        offset,
        sortColumn,
        sortDirection,
        search,
        userStatus,
        countryId,
        languageId,
      }) {
        return {
          url: generateUrlWithQueryParams('/admin/patients/pagination', {
            limit,
            offset,
            sortColumn,
            sortDirection,
            search,
            userStatus,
            countryId,
            languageId,
          }),
          method: HttpMethods.GET,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled.then(({ data }) => {
            if (data) dispatch(setAllPatients(data));
          });
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    activatePatient: builder.query<
      ActivatePatientResponse,
      ActivatePatientRequest
    >({
      query({ patientId }) {
        return {
          url: `/admin/patients/${patientId}/activate`,
          method: HttpMethods.PUT,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled.then(() => {
            dispatch(
              setPatientNewUserStatus({
                id: args.patientId,
                status: UserStatus.ACTIVE,
              })
            );
            toast.success('Doctor approved successfully');
          });
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    deactivatePatient: builder.query<
      DeactivatePatientResponse,
      DeactivatePatientRequest
    >({
      query({ patientId }) {
        return {
          url: `/admin/patients/${patientId}/deactivate`,
          method: HttpMethods.PUT,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled.then(() => {
            dispatch(
              setPatientNewUserStatus({
                id: args.patientId,
                status: UserStatus.DEACTIVATED,
              })
            );
            toast.success('Doctor rejected successfully');
          });
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    getPatientById: builder.query<
      GetPatientByIdResponse,
      GetPatientByIdRequest
    >({
      query({ patientId }) {
        return {
          url: `/admin/patients/${patientId}`,
          method: HttpMethods.GET,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    createPatient: builder.mutation<
      CreateUpdatePatientResponse,
      CreateUpdatePatientRequest
    >({
      query(data) {
        return {
          url: `/admin/patients/create`,
          method: HttpMethods.POST,
          body: data,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    updatePatient: builder.mutation<
      CreateUpdatePatientResponse,
      CreateUpdatePatientRequest
    >({
      query({id, data}) {
        return {
          url: `/admin/patients`,
          method: HttpMethods.PUT,
          body: { id, ...data},
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
    updatePatientProfilePicture: builder.mutation<
      UpdatePatientProfilePictureResponse,
      UpdatePatientProfilePictureRequest
    >({
      query({ patientId, formData }) {
        return {
          url: `/admin/patients/${patientId}/uploadpicture`,
          method: HttpMethods.POST,
          body: formData,
          useFormData: true,
          isWithAuth: true,
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled.then(({ data }) => data);
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyRejectDoctorQuery,
  useGetAllWithdrawalsQuery,
  useLazyApproveDoctorQuery,
  useLazyRejectWithdrawalQuery,
  useLazyApproveWithdrawalQuery,
  useLazyGetAllDoctorDocumentsByDoctorIdQuery,
  useLazyApproveDoctorDocumentQuery,
  useLazyRejectDoctorDocumentQuery,
  useLazyDeleteDoctorDocumentQuery,
  useUploadDoctorDocumentMutation,
  useCreateDoctorMutation,
  useUpdateDoctorMutation,
  useUpdateDoctorRatesMutation,
  useLazyGetAllDoctorEducationsByDoctorIdQuery,
  useLazyGetAllDoctorWorkExperiencesByDoctorIdQuery,
  useLazyDeleteDoctorEducationQuery,
  useLazyDeleteDoctorWorkExperienceQuery,
  useCreateDoctorEducationMutation,
  useCreateDoctorWorkExperienceMutation,
  useLazyGetAdminUserByIdQuery,
  useLazyGetAdminUsersQuery,
  useCreateAdminUserMutation,
  useUpdateAdminUserMutation,
  useUpdateAdminUserProfilePictureMutation,
  useLazyActivatePatientQuery,
  useLazyDeactivatePatientQuery,
  useLazyDeletePatientQuery,
  useLazyGetPaginatedPatientsQuery,
  useLazyGetPatientByIdQuery,
  useCreatePatientMutation,
  useUpdatePatientMutation,
  useUpdatePatientProfilePictureMutation,
} = adminApi;
