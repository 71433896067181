// @mui
import {
  enUS,
  frFR,
  zhCN,
  arSD,
  ruRU,
  deDE,
  esES,
  srRS,
  trTR,
  itIT,
  hiIN,
} from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'flagpack:gb-nir',
  },
  {
    label: 'French',
    value: 'fr',
    systemValue: frFR,
    icon: 'flagpack:fr',
  },
  {
    label: 'Chinese',
    value: 'zh',
    systemValue: zhCN,
    icon: 'flagpack:cn',
  },
  {
    label: 'Arabic (Sudan)',
    value: 'ar',
    systemValue: arSD,
    icon: 'flagpack:sd',
  },
  {
    label: 'Russian',
    value: 'ru',
    systemValue: ruRU,
    icon: 'flagpack:ru',
  },
  {
    label: 'German',
    value: 'de',
    systemValue: deDE,
    icon: 'flagpack:de',
  },
  {
    label: 'Spanish',
    value: 'es',
    systemValue: esES,
    icon: 'flagpack:es',
  },
  {
    label: 'Serbian',
    value: 'sr',
    systemValue: srRS,
    icon: 'flagpack:rs',
  },
  {
    label: 'Turkish',
    value: 'tr',
    systemValue: trTR,
    icon: 'flagpack:tr',
  },
  {
    label: 'Italian',
    value: 'it',
    systemValue: itIT,
    icon: 'flagpack:it',
  },
  {
    label: 'Hindi',
    value: 'hi',
    systemValue: hiIN,
    icon: 'flagpack:in',
  },
];

export const defaultLang = allLangs[0]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
