import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoginResponse } from '@redux/interfaces/auth';
import { User, UserAvatar } from '@redux/interfaces/user/interfaces';
import { RootState } from '@redux/store';

export interface ICurrentUserState {
  userInfo?: User;
  token: string;
}

const initialState: ICurrentUserState = {
  userInfo: undefined,
  token: '',
};

export const currentUser = createSlice({
  initialState,
  name: 'currentUser',
  reducers: {
    setUserWithToken: (state, action: PayloadAction<LoginResponse>) => {
      state.userInfo = action.payload.user;
      state.token = action.payload.token;
    },
    setToken: (state, action: PayloadAction<{ token: string }>) => {
      state.token = action.payload.token;
    },
    updateCurrentUser: (state, action: PayloadAction<User>) => {
      state.userInfo = { ...state.userInfo, ...action.payload };
    },
    removeCurrentUser: state => {
      state.userInfo = undefined;
      state.token = '';
    },
  },
});

export default currentUser.reducer;

export const {
  setUserWithToken,
  setToken,
  removeCurrentUser,
  updateCurrentUser,
} = currentUser.actions;

export const selectToken = (state: RootState): string =>
  state.currentUser?.token;
export const selectCurrentUser = (state: RootState): User | undefined =>
  state.currentUser.userInfo;

export const selectUserAvatar = (state: RootState): UserAvatar | undefined =>
  state.currentUser?.userInfo?.profilePicture;
