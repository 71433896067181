// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
// hooks
import { useResponsive } from '@hooks/use-responsive';
// theme
import { bgGradient } from '@theme/css';
// components
import Logo from '@components/logo';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children }: Props) {
  const theme = useTheme();

  const upMd = useResponsive('up', 'md');

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: { xs: 2, md: 5 },
      }}
    />
  );

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        py: { xs: 15, md: 30 },
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      alignItems='center'
      justifyContent='center'
      spacing={10}
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.68 : 0.94
          ),
          imgUrl: '/assets/images/login-bg.png',
        }),
      }}
    >
      {renderContent}
    </Stack>
  );

  return (
    <Stack
      component='main'
      direction='row'
      justifyContent='center'
      sx={{
        minHeight: '100vh',
      }}
    >
      {renderLogo}

      {upMd && renderSection}

      {!upMd && renderContent}
    </Stack>
  );
}
