import { useCallback } from 'react';
import { m } from 'framer-motion';
// @mui
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
// locales
import { useLocales } from '@src/locales';
// components
import Iconify from '@components/iconify';
import { varHover } from '@components/animate';
import CustomPopover, { usePopover } from '@components/custom-popover';

// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    icon: 'flagpack:gb-nir',
  },
  {
    label: 'French',
    value: 'fr',
    icon: 'flagpack:fr',
  },
  {
    label: 'Chinese',
    value: 'zh',
    icon: 'flagpack:cn',
  },
  {
    label: 'Arabic (Sudan)',
    value: 'ar',
    icon: 'flagpack:sd',
  },
  {
    label: 'Russian',
    value: 'ru',
    icon: 'flagpack:ru',
  },
  {
    label: 'German',
    value: 'de',
    icon: 'flagpack:de',
  },
  {
    label: 'Spanish',
    value: 'es',
    icon: 'flagpack:es',
  },
  {
    label: 'Serbian',
    value: 'sr',
    icon: 'flagpack:rs',
  },
  {
    label: 'Turkish',
    value: 'tr',
    icon: 'flagpack:tr',
  },
  {
    label: 'Italian',
    value: 'it',
    icon: 'flagpack:it',
  },
  {
    label: 'Hindi',
    value: 'hi',
    icon: 'flagpack:in',
  },
];

export default function LanguagePopover() {
  const locales = useLocales();
  const popover = usePopover();

  const handleChangeLang = useCallback(
    (newLang: string) => {
      locales.onChangeLang(newLang);
      popover.onClose();
    },
    [locales, popover]
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap='tap'
        whileHover='hover'
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          ...(popover.open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Iconify
          icon={locales.currentLang.icon}
          sx={{ borderRadius: 0.65, width: 28 }}
        />
      </IconButton>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 160 }}
      >
        {allLangs.map(option => (
          <MenuItem
            key={option.value}
            selected={option.value === locales.currentLang.value}
            onClick={() => handleChangeLang(option.value)}
          >
            <Iconify
              icon={option.icon}
              sx={{ borderRadius: 0.65, width: 28 }}
            />

            {option.label}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}
