import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { LoadingScreen } from 'src/components/loading-screen';
// routes
import { paths } from 'src/routes/paths';
import { usePathname, useRouter } from 'src/routes/hook';
// locales
import { useLocales } from 'src/locales';
//
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

const loginPaths: Record<string, string> = {
  jwt: paths.auth.jwt.login,
};

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { t } = useLocales();
  const { replace } = useRouter();
  const { isAuthenticated, isInitialized } = useAuthContext();
  const pathname = usePathname();

  useEffect(() => {
    if (!isInitialized) return;

    if (isAuthenticated) {
      return;
    }

    toast.error(t('toasts.unauth'));
    replace(loginPaths.jwt);
  }, [isAuthenticated, isInitialized, pathname, replace, t]);

  if (!isInitialized || !isAuthenticated)
    return <LoadingScreen sx={{ width: '100%' }} />;

  return <> {children} </>;
}
