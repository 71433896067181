import { Navigate, useRoutes } from 'react-router-dom';
// config
import { PATH_AFTER_LOGIN } from '@src/config-global';
//
import { mainRoutes } from './main';

import { authRoutes } from './auth';
import { usersRoutes } from './users';
import { doctorsRoutes } from './doctors';
import { patientsRoutes } from './patients';
import { dashboardRoutes } from './dashboard';
import { discountsRoutes } from './discounts';
import { withdrawalsRoutes } from './withdrawals';
import { departmentsRoutes } from './departments';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Users routes
    ...usersRoutes,

    // Doctors routes
    ...doctorsRoutes,

    // Patients routes
    ...patientsRoutes,

    // Departments routes
    ...departmentsRoutes,

    // Withdrawals routes
    ...withdrawalsRoutes,

    // Discounts routes
    ...discountsRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to='/404' replace /> },
  ]);
}
