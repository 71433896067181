import { forwardRef } from 'react';
// @mui
import Box, { BoxProps } from '@mui/material/Box';
import Link from '@mui/material/Link';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx }, ref) => {
    const logo = (
      <Box
        ref={ref}
        component='img'
        src='/logo/logo.svg'
        sx={{ width: 200, height: 80, cursor: 'pointer', ...sx }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href='/' sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
