import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Patient, GetPaginatedPatientsApiResponse } from '@redux/interfaces/patients';
import { UserStatus } from '@redux/interfaces/user/enum';
import { RootState } from '@redux/store';

export interface IPatientsState {
  allPatients: Patient[];
  totalElements: number;
  totalPages: number;
}

const initialState: IPatientsState = {
  allPatients: [],
  totalElements: 0,
  totalPages: 0,
};

export const patients = createSlice({
  initialState,
  name: 'patients',
  reducers: {
    setAllPatients: (
      state,
      { payload }: PayloadAction<GetPaginatedPatientsApiResponse>
    ) => {
      state.allPatients = payload.entities;
      state.totalElements = payload.totalElements;
      state.totalPages = payload.totalPages;
    },
    setPatientNewUserStatus: (
      state,
      action: PayloadAction<{ id: number; status: UserStatus }>
    ) => {
      state.allPatients = [
        ...state.allPatients.map(patient => {
          const newPatient = { ...patient };

          if (newPatient.id === action.payload.id) {
            newPatient.userStatus = action.payload.status;
          }

          return newPatient;
        }),
      ];
    },
  },
});

export default patients.reducer;

export const { setAllPatients, setPatientNewUserStatus } = patients.actions;

export const selectAllPatients = (state: RootState): Patient[] | [] =>
  state.patients.allPatients;

export const selectAllPatientsTotalPages = (state: RootState): number =>
  state.patients.totalPages;

export const selectAllPatientsTotalElements = (state: RootState): number =>
  state.patients.totalElements;
