import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Discount } from '@redux/interfaces/discounts';
import { RootState } from '@redux/store';

export interface IDiscountsState {
  discounts: Discount[] | [];
}

const initialState: IDiscountsState = {
  discounts: [],
};

export const discounts = createSlice({
  initialState,
  name: 'discounts',
  reducers: {
    setDiscounts: (state, action: PayloadAction<Discount[]>) => {
      state.discounts = action.payload;
    },
    addDiscount: (state, action: PayloadAction<Discount>) => {
      const newDiscount = action.payload;
      const updatedDiscounts = [ ...state.discounts ];
      updatedDiscounts.push(newDiscount);
      state.discounts = updatedDiscounts;
    },
    deleteDiscount: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      const previousDiscounts = [ ...state.discounts ];
      state.discounts = previousDiscounts.filter(discount => discount.id !== id);
    },
    updateCurrentDiscount: (
      state,
      action: PayloadAction<Discount>
    ) => {
      state.discounts = [
        ...state.discounts.map(discount => {
          const newDiscount = { ...discount };

          if (newDiscount.id === action.payload.id) {
            newDiscount.status = action.payload.status;
          }

          return newDiscount;
        }),
      ];
    },
  },
});

export default discounts.reducer;

export const { setDiscounts, addDiscount, deleteDiscount, updateCurrentDiscount } = discounts.actions;

export const selectAllDiscounts = (state: RootState): Discount[] =>
  state.discounts.discounts;
