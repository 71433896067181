// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  PATIENTS: '/patients',
  DOCTORS: '/doctors',
  USERS: '/users',
  DEPARTMENTS: '/departments',
  WITHDRAWALS: '/withdrawals',
  DISCOUNTS: '/discounts',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/login`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
  },
  // USERS
  // users: {
  //   root: ROOTS.USERS
  // },
  // DOCTORS
  doctors: {
    root: ROOTS.DOCTORS,
    new: `${ROOTS.DOCTORS}/new`,
    details: (id: string) => `${ROOTS.DOCTORS}/${id}`,
  },
  // PATIENTS
  patients: {
    root: ROOTS.PATIENTS,
    new: `${ROOTS.PATIENTS}/new`,
    details: (id: string) => `${ROOTS.PATIENTS}/${id}`,
  },
  // DEPARTMENTS
  departments: {
    root: ROOTS.DEPARTMENTS,
    new: `${ROOTS.DEPARTMENTS}/new`,
    details: (id: string) => `${ROOTS.DEPARTMENTS}/${id}`,
  },
  // WITHDRAWALS
  withdrawals: {
    root: ROOTS.WITHDRAWALS
  },
  // DISCOUNTS
  discounts: {
    root: ROOTS.DISCOUNTS,
    new: `${ROOTS.DISCOUNTS}/new`,
    details: (id: string) => `${ROOTS.DISCOUNTS}/${id}`,
  },
};
