import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosRequestConfig } from 'axios';

import { removeCurrentUser } from '@redux/slices/currentUser';
import { RootState } from '@redux/store';
import { LocalStorageNames } from '@src/constants';
import axios, { axiosError } from '@utils/axios';
import { globalErrorToast } from '@utils/toast';

const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig['method'];
      body?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
      isWithAuth?: boolean;
    },
    unknown,
    unknown
  > =>
  async (
    { url, method, body: data, params, headers, isWithAuth },
    { getState, dispatch }
  ) => {
    try {
      const accessToken =
        (getState() as RootState).currentUser.token ||
        localStorage.getItem(LocalStorageNames.TOKEN);

      const authHeaders = {
        'x-jwt-token': '',
      };

      if (accessToken && isWithAuth) {
        authHeaders['x-jwt-token'] = accessToken;
      }

      const res = await axios({
        url,
        method,
        data,
        params,
        headers: isWithAuth ? { ...headers, ...authHeaders } : headers,
      });

      return { data: res.data };
    } catch (err) {
      const error = axiosError(err);

      if (error?.error?.status === 401) {
        dispatch(removeCurrentUser());
        localStorage.removeItem(LocalStorageNames.TOKEN);
      }

      globalErrorToast(err);

      return error;
    }
  };

export const baseCreateApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  reducerPath: 'baseCreateApi',
});
