import React, {
  createContext,
  useState,
  useMemo,
  SetStateAction,
  Dispatch,
  useCallback,
} from 'react';

import { SortColumn } from '@redux/interfaces/commonEnums';

interface DepartmentsContextProps {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  dense: boolean;
  setDense: Dispatch<SetStateAction<boolean>>;
  sortColumnValue: string;
  setSortColumnValue: Dispatch<SetStateAction<string>>;
  sortColumnDirection: number;
  setSortColumnDirection: Dispatch<SetStateAction<number>>;
  resetDepartmentsContext: Function;
}

export const DepartmentsContext = createContext<DepartmentsContextProps>({
  currentPage: 1,
  setCurrentPage: () => {},
  rowsPerPage: 5,
  setRowsPerPage: () => {},
  dense: false,
  setDense: () => {},
  sortColumnValue: SortColumn.NAME,
  setSortColumnValue: () => {},
  sortColumnDirection: 1,
  setSortColumnDirection: () => {},
  resetDepartmentsContext: () => {},
});

interface DepartmentsProviderProps {
  children: React.ReactNode;
}

export const DepartmentsProvider: React.FC<DepartmentsProviderProps> = ({
  children,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [dense, setDense] = useState<boolean>(false);
  const [sortColumnValue, setSortColumnValue] = useState<string>(
    SortColumn.NAME
  );
  const [sortColumnDirection, setSortColumnDirection] = useState<number>(1);

  const resetDepartmentsContext = useCallback(() => {
    setCurrentPage(1);
    setRowsPerPage(5);
    setDense(false);
    setSortColumnValue(SortColumn.NAME);
    setSortColumnDirection(1);
  }, [
    setCurrentPage,
    setRowsPerPage,
    setDense,
    setSortColumnValue,
    setSortColumnDirection,
  ]);

  const CONTEXT_VALUE = useMemo(
    () => ({
      currentPage,
      setCurrentPage,
      rowsPerPage,
      setRowsPerPage,
      dense,
      setDense,
      sortColumnValue,
      setSortColumnValue,
      sortColumnDirection,
      setSortColumnDirection,
      resetDepartmentsContext,
    }),
    [
      currentPage,
      setCurrentPage,
      rowsPerPage,
      setRowsPerPage,
      dense,
      setDense,
      sortColumnValue,
      setSortColumnValue,
      sortColumnDirection,
      setSortColumnDirection,
      resetDepartmentsContext,
    ]
  );

  return (
    <DepartmentsContext.Provider value={CONTEXT_VALUE}>
      {children}
    </DepartmentsContext.Provider>
  );
};
