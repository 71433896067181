import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Withdrawal } from '@redux/interfaces/admin';
import { WithdrawalStatus } from '@redux/interfaces/user/enum';
import { RootState } from '@redux/store';

export interface IWithdrawalsState {
  withdrawals: Withdrawal[] | [];
}

const initialState: IWithdrawalsState = {
  withdrawals: [],
};

export const withdrawals = createSlice({
  initialState,
  name: 'withdrawals',
  reducers: {
    setWithdrawals: (state, action: PayloadAction<Withdrawal[]>) => {
      state.withdrawals = action.payload;
    },
    setWithdrawalNewStatus: (
      state,
      action: PayloadAction<{ id: number; status: WithdrawalStatus }>
    ) => {
      state.withdrawals = [
        ...state.withdrawals.map(withdrawal => {
          const newWithdrawal = { ...withdrawal };

          if (newWithdrawal.withdrawalId === action.payload.id) {
            newWithdrawal.status = action.payload.status;
          }

          return newWithdrawal;
        }),
      ];
    },
  },
});

export default withdrawals.reducer;

export const { setWithdrawals, setWithdrawalNewStatus } = withdrawals.actions;

export const selectAllWithdrawals = (state: RootState): Withdrawal[] =>
  state.withdrawals.withdrawals;
