import jwt_decode from 'jwt-decode';
import { useRouter } from 'src/routes/hook';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  removeCurrentUser,
  selectToken,
  setToken,
  updateCurrentUser,
} from '@redux/slices/currentUser';

import { useLazyGetAdminUserByIdQuery } from '@redux/api/adminApi';
import { localStorageAvailable } from '@utils/storage-available';
import { useAppDispatch, useAppSelector } from '@redux/store';
import { LocalStorageNames } from '@constants/enums';
import { DecodedJWT } from 'src/auth/interfaces';
import { paths } from 'src/routes/paths';

import { JWTContextType } from './types';
import { isValidToken } from './utils';

export const AuthContext = createContext<JWTContextType | null>(null);

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const accessToken = useAppSelector(selectToken);

  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const storageAvailable = localStorageAvailable();

  const [getAdminUserById] = useLazyGetAdminUserByIdQuery();

  const logout = useCallback(async () => {
    await router.replace(paths.auth.jwt.login);

    setIsAuthenticated(false);

    localStorage.removeItem(LocalStorageNames.TOKEN);
    dispatch(removeCurrentUser);
  }, [router, dispatch]);

  useEffect(() => {
    (async () => {
      let decodedToken: DecodedJWT = { USER_TYPE: null, sub: '' };
      const accessTokenFromLocalStorage = storageAvailable
        ? localStorage.getItem(LocalStorageNames.TOKEN)
        : '';

      if (accessToken || accessTokenFromLocalStorage) {
        decodedToken = jwt_decode(
          accessToken || accessTokenFromLocalStorage || ''
        );
      }

      if (accessToken) {
        setIsAuthenticated(true);
      }

      if (
        !accessToken &&
        accessTokenFromLocalStorage &&
        isValidToken(accessTokenFromLocalStorage)
      ) {
        dispatch(setToken({ token: accessTokenFromLocalStorage }));

        const { isSuccess, data } = await getAdminUserById(
          { adminId: Number(decodedToken.sub) },
          true
        );

        if (isSuccess) dispatch(updateCurrentUser(data));
        setIsAuthenticated(true);
      }

      if (!accessToken && !isValidToken(accessTokenFromLocalStorage || '')) {
        setIsAuthenticated(false);
      }

      setIsInitialized(true);
    })();
  }, [storageAvailable, accessToken, getAdminUserById, dispatch]);

  const memoizedValue = useMemo(
    () => ({
      isInitialized,
      isAuthenticated,
      logout,
    }),
    [isAuthenticated, isInitialized, logout]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
