import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Department } from '@redux/interfaces/departments';
import { RootState } from '@redux/store';

export interface IDepartmentsState {
  departments: Department[] | [];
}

const initialState: IDepartmentsState = {
  departments: [],
};

export const departments = createSlice({
  initialState,
  name: 'departments',
  reducers: {
    setDepartments: (state, action: PayloadAction<Department[]>) => {
      state.departments = action.payload;
    },
    addDepartment: (state, action: PayloadAction<Department>) => {
      const newDepartment = action.payload;
      const updatedDepartments = [ ...state.departments ];
      updatedDepartments.push(newDepartment);
      state.departments = updatedDepartments;
    },
    deleteDepartment: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      const previousDepartments = [ ...state.departments ];
      state.departments = previousDepartments.filter(department => department.id !== id);
    },
    updateCurrentDepartment: (
      state,
      action: PayloadAction<Department>
    ) => {
      state.departments = [
        ...state.departments.map(department => {
          const newDepartment = { ...department };

          if (newDepartment.id === action.payload.id) {
            newDepartment.name = action.payload.name;
            newDepartment.image = action.payload.image;
          }

          return newDepartment;
        }),
      ];
    },
  },
});

export default departments.reducer;

export const { setDepartments, addDepartment, deleteDepartment, updateCurrentDepartment } = departments.actions;

export const selectAllDepartments = (state: RootState): Department[] =>
  state.departments.departments;
