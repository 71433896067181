import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from '@src/auth/guard';
// layouts
import DashboardLayout from '@layouts/dashboard';
// components
import { LoadingScreen } from '@components/loading-screen';

// ----------------------------------------------------------------------

const DoctorListPage = lazy(() => import('@pages/doctors/list'));
const DoctorNewPage = lazy(() => import('@pages/doctors/new'));
const DoctorDetailsPage = lazy(() => import('@pages/doctors/details'));

// ----------------------------------------------------------------------

export const doctorsRoutes = [
  {
    path: 'doctors',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <DoctorListPage />, index: true },
      { path: 'new', element: <DoctorNewPage /> },
      { path: ':id', element: <DoctorDetailsPage /> },
    ],
  },
];
