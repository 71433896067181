import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from '@src/auth/guard';
// layouts
import DashboardLayout from '@layouts/dashboard';
// components
import { LoadingScreen } from '@components/loading-screen';

// ----------------------------------------------------------------------

const PatientListPage = lazy(() => import('@pages/patients/list'));
const PatientNewPage = lazy(() => import('@pages/patients/new'));
const PatientDetailsPage = lazy(() => import('@pages/patients/details'));

// ----------------------------------------------------------------------

export const patientsRoutes = [
  {
    path: 'patients',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <PatientListPage />, index: true },
      { path: 'new', element: <PatientNewPage /> },
      { path: ':id', element: <PatientDetailsPage /> },
    ],
  },
];
