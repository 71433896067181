import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Doctor, GetPaginatedDoctorsApiResponse } from '@redux/interfaces/doctors';
import { UserStatus } from '@redux/interfaces/user/enum';
import { RootState } from '@redux/store';

export interface IDoctorsState {
  allDoctors: Doctor[];
  totalElements: number;
  totalPages: number;
}

const initialState: IDoctorsState = {
  allDoctors: [],
  totalElements: 0,
  totalPages: 0,
};

export const doctors = createSlice({
  initialState,
  name: 'doctors',
  reducers: {
    setAllDoctors: (
      state,
      { payload }: PayloadAction<GetPaginatedDoctorsApiResponse>
    ) => {
      state.allDoctors = payload.entities;
      state.totalElements = payload.totalElements;
      state.totalPages = payload.totalPages;
    },
    setDoctorNewUserStatus: (
      state,
      action: PayloadAction<{ id: number; status: UserStatus }>
    ) => {
      state.allDoctors = [
        ...state.allDoctors.map(doctor => {
          const newDoctor = { ...doctor };

          if (newDoctor.id === action.payload.id) {
            newDoctor.userStatus = action.payload.status;
          }

          return newDoctor;
        }),
      ];
    },
  },
});

export default doctors.reducer;

export const { setAllDoctors, setDoctorNewUserStatus } = doctors.actions;

export const selectAllDoctors = (state: RootState): Doctor[] | [] =>
  state.doctors.allDoctors;

export const selectAllDoctorsTotalPages = (state: RootState): number =>
  state.doctors.totalPages;

export const selectAllDoctorsTotalElements = (state: RootState): number =>
  state.doctors.totalElements;
