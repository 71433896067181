import doctorsReducer from '@redux/slices/doctors';
import patientsReducer from '@redux/slices/patients';
import languagesReducer from '@redux/slices/languages';
import countriesReducer from '@redux/slices/countries';
import departmentsReducer from '@redux/slices/departments';
import withdrawalsReducer from '@redux/slices/withdrawals';
import discountsReducer from '@redux/slices/discounts';
import currentUserReducer from '@redux/slices/currentUser';

const rootReducer = {
  currentUser: currentUserReducer,
  doctors: doctorsReducer,
  patients: patientsReducer,
  languages: languagesReducer,
  countries: countriesReducer,
  departments: departmentsReducer,
  withdrawals: withdrawalsReducer,
  discounts: discountsReducer,
};

export default rootReducer;
