import React, {
  createContext,
  useState,
  useMemo,
  SetStateAction,
  Dispatch,
  useCallback,
} from 'react';

import { Booking } from '@src/types/patient';
import { Discount } from '@redux/interfaces/discounts';
import { SortColumn } from '@redux/interfaces/commonEnums';
import { DiscountStatus } from '@redux/interfaces/user/enum';

interface DiscountsContextProps {
  discountInfo: Discount | null;
  setDiscountInfo: Dispatch<SetStateAction<Discount | null>>;
  bookings: Booking[];
  setBookings: Dispatch<SetStateAction<Booking[]>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  dense: boolean;
  setDense: Dispatch<SetStateAction<boolean>>;
  searchCodeValue: string;
  setSearchCodeValue: Dispatch<SetStateAction<string>>;
  searchOwnerValue: string;
  setSearchOwnerValue: Dispatch<SetStateAction<string>>;
  status: DiscountStatus | 'all';
  setStatus: Dispatch<SetStateAction<DiscountStatus | 'all'>>;
  sortColumnValue: string;
  setSortColumnValue: Dispatch<SetStateAction<string>>;
  sortColumnDirection: number;
  setSortColumnDirection: Dispatch<SetStateAction<number>>;
  resetDiscountsContext: Function;
}

export const DiscountsContext = createContext<DiscountsContextProps>({
  discountInfo: null,
  setDiscountInfo: () => {},
  bookings: [],
  setBookings: () => {},
  currentPage: 1,
  setCurrentPage: () => {},
  rowsPerPage: 5,
  setRowsPerPage: () => {},
  dense: false,
  setDense: () => {},
  status: 'all',
  setStatus: () => {},
  searchCodeValue: '',
  setSearchCodeValue: () => {},
  searchOwnerValue: '',
  setSearchOwnerValue: () => {},
  sortColumnValue: SortColumn.NAME,
  setSortColumnValue: () => {},
  sortColumnDirection: 1,
  setSortColumnDirection: () => {},
  resetDiscountsContext: () => {},
});

interface DiscountsProviderProps {
  children: React.ReactNode;
}

export const DiscountsProvider: React.FC<DiscountsProviderProps> = ({
  children,
}) => {
  const [discountInfo, setDiscountInfo] = useState<Discount | null>(null);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [dense, setDense] = useState<boolean>(false);
  const [status, setStatus] = useState<DiscountStatus | 'all'>('all');
  const [searchCodeValue, setSearchCodeValue] = useState<string>('');
  const [searchOwnerValue, setSearchOwnerValue] = useState<string>('');
  const [sortColumnValue, setSortColumnValue] = useState<string>(
    SortColumn.NAME
  );
  const [sortColumnDirection, setSortColumnDirection] = useState<number>(1);

  const resetDiscountsContext = useCallback(() => {
    setDiscountInfo(null);
    setBookings([]);
    setCurrentPage(1);
    setRowsPerPage(5);
    setDense(false);
    setStatus('all');
    setSearchCodeValue('');
    setSearchOwnerValue('');
    setSortColumnValue(SortColumn.NAME);
    setSortColumnDirection(1);
  }, [
    setDiscountInfo,
    setBookings,
    setCurrentPage,
    setRowsPerPage,
    setDense,
    setStatus,
    setSearchCodeValue,
    setSearchOwnerValue,
    setSortColumnValue,
    setSortColumnDirection,
  ]);

  const CONTEXT_VALUE = useMemo(
    () => ({
      discountInfo,
      setDiscountInfo,
      bookings,
      setBookings,
      currentPage,
      setCurrentPage,
      rowsPerPage,
      setRowsPerPage,
      dense,
      setDense,
      status,
      setStatus,
      searchCodeValue,
      setSearchCodeValue,
      searchOwnerValue,
      setSearchOwnerValue,
      sortColumnValue,
      setSortColumnValue,
      sortColumnDirection,
      setSortColumnDirection,
      resetDiscountsContext,
    }),
    [
      discountInfo,
      setDiscountInfo,
      bookings,
      setBookings,
      currentPage,
      setCurrentPage,
      rowsPerPage,
      setRowsPerPage,
      dense,
      setDense,
      status,
      setStatus,
      searchCodeValue,
      setSearchCodeValue,
      searchOwnerValue,
      setSearchOwnerValue,
      sortColumnValue,
      setSortColumnValue,
      sortColumnDirection,
      setSortColumnDirection,
      resetDiscountsContext,
    ]
  );

  return (
    <DiscountsContext.Provider value={CONTEXT_VALUE}>
      {children}
    </DiscountsContext.Provider>
  );
};
